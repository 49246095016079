import { Payment } from "../../../types/Payment";
import { ConsumerPaymentMethod } from "../../../types/PaymentMethod";

export const getConsumerCardFrom = (values: Payment): ConsumerPaymentMethod => {
  const creditCard: any = {};

  if (values.name) {
    creditCard.name = values.name;
  }
  if (values.number) {
    creditCard.number = values.number;
  }
  if (values.date) {
    creditCard.expiry_month = values.date.split("/")[0].trim();
    creditCard.expiry_year = values.date.split("/")[1].trim();
  }

  if (values.cvv) {
    creditCard.cvv = values.cvv;
  }

  return {
    consumer_credit_card: creditCard,
  };
};

export const mergeConsumerCardValues = ({
  method,
  values,
}: {
  method: ConsumerPaymentMethod;
  values: Payment;
}): ConsumerPaymentMethod => {
  return {
    ...method,
    consumer_credit_card: {
      ...(method ? method.consumer_credit_card : {}),
      ...getConsumerCardFrom(values).consumer_credit_card,
    },
  };
};

export const getPaymentValuesFrom = (
  method: ConsumerPaymentMethod,
  code?: string
): Payment => {
  if (method.consumer_credit_card) {
    const {
      name,
      number,
      expiry_month,
      expiry_year,
    } = method.consumer_credit_card;
    return {
      name: name,
      number: number,
      date: `${expiry_month} / ${expiry_year}`,
      cvv: code ? code : "",
    };
  }

  return {
    name: method.name,
  };
};
