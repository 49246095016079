import React from "react";

import { navigate } from "@reach/router";
import { useWindowDimensions } from "react-native";
import { connect, ConnectedProps } from "react-redux";

// TOOD: replace imports w/ local scoped
import { CartReduxState } from "cuanto/features/Cart";
import * as catalogueActions from "cuanto/web-app/actions";
import { State as CatalogueState } from "cuanto/web-app/reducers";
// END TODO

import useTracker from "../../hooks/useTracker";
import LoadingView from "../components/LoadingView";
import useCatalogue from "../contexts/CatalogueContext";
import { isFromInstagramBrowser } from "../services/Tracking";
import { Product } from "../types";
import Catalogue from "./Catalogue";

type Props = ReduxProps & {
  username: string;
  tracker?: string;
};

function CatalogueScreen(props: Props) {
  const {
    username,
    selectedCategoryUuid,
    cartQuantity,
    transactionCountPastThreeMonths,
    selectCategoryUuid,
    tracker,
  } = props;

  const { width } = useWindowDimensions();
  const numColumns = width < 600 || isFromInstagramBrowser() ? 2 : 3;

  const { catalogueData, priceGroups, isLoading, addToCart } = useCatalogue();

  useTracker(tracker);

  if (isLoading) return <LoadingView />;

  if (
    !catalogueData.merchant.has_ecommerce_store ||
    !catalogueData.products.length
  ) {
    navigate(`/${username}/c`);
  }

  const storeClosed = catalogueData.settings.some(
    (s) => s.key === "store_closed" && s.value === "t"
  );

  function onAddToCart(product: Product) {
    const { redirectTo } = addToCart(product);
    const shortUuid = (product.product_group_uuid || product.uuid).slice(-6);
    switch (redirectTo) {
      case "prices":
        navigate(`/${username}/p/${shortUuid}/prices`);
        break;
      case "cart":
        navigate(`/${username}/cart`);
        break;
      case "catalogue": // no-op, we're already here
        break;
    }
  }

  function onGoToCart() {
    navigate(`/${username}/cart`);
  }

  function onSelectCategory(uuid: string) {
    selectCategoryUuid(uuid);

    if (uuid === "all") {
      navigate(`/${username}`);
    } else {
      navigate(`/${username}/category/${uuid}`);
    }
  }

  function onSelectProduct(product: Product) {
    navigate(`/${username}/p/${product.short_uuid}`);
  }

  const filteredProducts =
    selectedCategoryUuid === "all"
      ? catalogueData.products.filter((p) => p.product_group_uuid === null)
      : catalogueData.products.filter((p) => {
          const uuids = p.categories.map((c) => c.uuid);
          return uuids.includes(selectedCategoryUuid);
        });

  const productGroups: Record<string, Product[]> = {};
  catalogueData.products.forEach((product) => {
    if (!product.product_group_uuid) return;
    if (!productGroups[product.product_group_uuid]) {
      productGroups[product.product_group_uuid] = [];
    }
    productGroups[product.product_group_uuid].push(product);
  });

  function navigateToMoreInfo() {
    navigate(`/${username}/more_info`);
  }

  return (
    <Catalogue
      merchant={catalogueData.merchant}
      products={filteredProducts}
      categories={catalogueData.categories}
      productGroups={productGroups}
      selectedCategoryUuid={selectedCategoryUuid}
      storeConfig={catalogueData.store_config}
      reviewsAverage={catalogueData.reviews_average}
      totalTransactionCount={catalogueData.total_transaction_count}
      deliveryEnabled={catalogueData.has_fulfillments_enabled}
      cartQuantity={cartQuantity}
      transactionCountPastThreeMonths={transactionCountPastThreeMonths}
      storeClosed={storeClosed}
      numCatalogueColumns={numColumns}
      priceGroups={priceGroups}
      onAddToCart={onAddToCart}
      onGoToCart={onGoToCart}
      onSelectProduct={onSelectProduct}
      onSelectCategory={onSelectCategory}
      onMoreInfoPress={navigateToMoreInfo}
    />
  );
}

type State = { catalogue: CatalogueState; cart: CartReduxState };
const mapStateToProps = ({ catalogue, cart }: State) => ({
  selectedCategoryUuid: catalogue.selectedCategoryUuid,
  cartQuantity: cart.quantity,
  transactionCountPastThreeMonths:
    catalogue.transactionCountPastThreeMonths || 0,
});

const mapDispatchToProps = {
  selectCategoryUuid: catalogueActions.selectCategoryUuid,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CatalogueScreen);
