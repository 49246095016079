import { createSlice } from "@reduxjs/toolkit";
import { groupBy, isEmpty, keyBy } from "lodash";

import { FeatureFlags } from "cuanto/types/FeatureFlags";
import { FulfillmentOption } from "cuanto/types/FulfillmentOption";

import { Category } from "../types/Category";
import { CountryCode } from "../types/CountryCode";
import { Error } from "../types/Error";
import { Merchant } from "../types/Merchant";
import { PaymentMethod } from "../types/PaymentMethod";
import { WebPaymentRequest } from "../types/PaymentRequest";
import { Product } from "../types/Product";
import { Review } from "../types/Review";
import { SettingValue } from "../types/UserSetting";
import * as actions from "./actions";
import { PaymentResultData } from "./actions";
import { StoreConfig } from "./components/StorePersonalization/types";
import { PayForCartPayload } from "./types";

export { State as CatalogueState };

export interface State {
  paymentError: Error;
  isCreatingCartPayment: boolean;
  hasCreatingCartPaymentErrored: boolean;

  isCatalogueFetched: boolean;
  isFetchingCatalogue: boolean;
  hasFetchingCatalogueErrored: boolean;

  isFetchingPaymentRequest: boolean;
  hasFetchingPaymentRequestErrored: boolean;

  storeConfig: StoreConfig;
  featureFlags: FeatureFlags;

  isCatalogueLoading: {
    inventory: boolean;
    payment: boolean;
  };
  hasCatalogueLoadingErrored: {
    inventory: boolean;
    payment: boolean;
  };
  transactionCountPastThreeMonths: number | null;
  totalTransactionCount: number | null;
  countryCode: CountryCode;
  merchant: Merchant;
  products: Product[];
  productGroupsById: { [key: string]: Product[] };
  categories: Category[];
  categoriesById: Object;

  payment: PayForCartPayload;
  isCreatingCataloguePayment: boolean;
  hasCreatingCataloguePaymentErrored: boolean;

  fulfillmentOptions: FulfillmentOption[];
  fulfillmentOptionsSetting: { key: string; value: string } | null;
  hasFulfillmentOptions: boolean;
  storeClosed: boolean;
  paymentTypes: string[];
  paymentMethods: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod | {};

  bankAccount: any;
  paymentRequest: {} | WebPaymentRequest;
  isPaymentRequest: boolean;

  hasManyPaymentTypes: boolean;

  isFetchingReviews: boolean;
  hasFetchingReviewsErrored: boolean;
  reviews: Review[];
  reviewsCount: number;
  reviewsAverage: number | null;
  paymentResult: actions.PaymentResultData | null;
  selectedCategoryUuid: string;
}

const initialState: State = {
  countryCode: "507",

  merchant: null,
  products: [],
  productGroupsById: {},
  categories: [],
  categoriesById: {},

  storeConfig: { uuid: null, banner_image_url: null, bio: null },
  featureFlags: { store_personalization_enabled: false, fulfillments: false },

  isCatalogueFetched: false,
  isFetchingCatalogue: false,
  hasFetchingCatalogueErrored: false,

  payment: null,
  paymentError: null,
  isCreatingCataloguePayment: false,
  hasCreatingCataloguePaymentErrored: false,
  isCreatingCartPayment: false,
  hasCreatingCartPaymentErrored: false,

  hasFulfillmentOptions: false,
  fulfillmentOptions: [],
  fulfillmentOptionsSetting: null,
  storeClosed: false,
  selectedPaymentMethod: {},

  paymentTypes: [],
  paymentMethods: [],
  bankAccount: null,

  isFetchingPaymentRequest: true,
  hasFetchingPaymentRequestErrored: false,
  paymentRequest: {},
  isPaymentRequest: false,

  isCatalogueLoading: {
    inventory: false,
    payment: false,
  },
  hasCatalogueLoadingErrored: {
    inventory: false,
    payment: false,
  },
  transactionCountPastThreeMonths: null,
  totalTransactionCount: null,
  hasManyPaymentTypes: null,

  isFetchingReviews: true,
  hasFetchingReviewsErrored: false,
  reviews: [],
  reviewsCount: 0,
  reviewsAverage: null,
  paymentResult: null,
  selectedCategoryUuid: "all",
};

const catalogue = createSlice({
  name: "catalogue",
  initialState,
  reducers: {
    // Slice reducers can't use imported actions because they create new actions
  },
  extraReducers: {
    [actions.fetchCatalogueStart.type]: (state) => {
      state.isCatalogueFetched = false;
      state.isFetchingCatalogue = true;
      state.hasFetchingCatalogueErrored = false;
    },
    [actions.fetchCatalogue.type]: (state, { payload }) => {
      state.productGroupsById = groupBy(payload.products, "product_group_uuid");
      state.products = state.productGroupsById["null"] || [];

      state.categories = payload.categories;
      state.categoriesById = keyBy(payload.categories, "uuid");
      state.merchant = payload.merchant;
      state.countryCode = payload.country_code;

      state.storeConfig = payload.store_config;
      state.featureFlags = payload.feature_flags;

      // Merchants with subscriptions enabled will have a digital fulfillment,
      // but they might not necessarily use fulfillments in regular checkout
      state.hasFulfillmentOptions =
        payload.has_fulfillments_enabled &&
        !isEmpty(payload.fulfillment_options);
      state.fulfillmentOptions = payload.fulfillment_options;
      state.fulfillmentOptionsSetting = payload.settings.filter(
        (setting) => setting.key === "fulfillment_details"
      )[0];
      state.storeClosed =
        payload.settings.find((s) => s.key === "store_closed")?.value ===
          SettingValue.True.valueOf() || false;

      state.paymentTypes = payload.payment_method_types;
      state.paymentMethods = payload.payment_methods;
      state.hasManyPaymentTypes = payload.payment_method_types.length > 1;
      state.bankAccount = payload.bank_account;

      state.reviewsCount = payload.reviews_count;
      state.reviewsAverage = payload.reviews_average;
      state.totalTransactionCount = payload.total_transaction_count;

      // if we only loaded 1 product (via product link, we still need to load the catalogue)
      if (
        typeof payload.isProductOnly === "undefined" ||
        !payload.isProductOnly
      ) {
        state.isCatalogueFetched = true;
      }
      state.isFetchingCatalogue = false;
      state.hasFetchingCatalogueErrored = false;
    },
    [actions.fetchCatalogueError.type]: (state) => {
      state.isCatalogueFetched = true;
      state.isFetchingCatalogue = false;
      state.hasFetchingCatalogueErrored = true;
    },

    [actions.resetCartPayment.type]: (state) => {
      state.payment = null;
    },
    [actions.selectPaymentMethodType.type]: (state, { payload }) => {
      state.selectedPaymentMethod = payload;
    },
    [actions.resetPaymentMethodType.type]: (state, { payload }) => {
      state.selectedPaymentMethod = initialState.selectedPaymentMethod;
    },

    [actions.createCartPaymentStart.type]: (state) => {
      state.payment = null;
      state.paymentError = null;
      state.isCreatingCartPayment = true;
      state.hasCreatingCartPaymentErrored = false;
    },
    [actions.createCartPayment.type]: (
      state,
      action: { payload: PayForCartPayload }
    ) => {
      state.payment = action.payload;
      state.paymentError = null;

      state.isCreatingCartPayment = false;
      state.hasCreatingCartPaymentErrored = false;
    },
    [actions.createCartPaymentError.type]: (
      state: State,
      { payload }: actions.CartPaymentErrorAction
    ) => {
      state.payment = null;
      state.paymentError = payload;
      state.isCreatingCartPayment = false;
      state.hasCreatingCartPaymentErrored = true;
    },

    [actions.fetchPaymentRequestStart.type]: (state) => {
      state.isFetchingPaymentRequest = true;
      state.hasFetchingPaymentRequestErrored = false;
    },
    [actions.fetchPaymentRequest.type]: (state, { payload }) => {
      state.paymentRequest = payload;
      (state.paymentRequest as WebPaymentRequest).paymentRequestUuid =
        payload.paymentRequestUuid;
      state.isPaymentRequest = !isEmpty(payload.paymentRequestUuid);

      state.merchant = payload.merchant;

      // Merchants with subscriptions enabled will have a global digital fulfillment,
      // but this doesn't mean they use fulfillments in regular checkout
      state.hasFulfillmentOptions =
        payload.has_fulfillments_enabled &&
        !isEmpty(payload.fulfillment_options);
      state.fulfillmentOptions = payload.fulfillment_options;
      state.fulfillmentOptionsSetting = payload.settings.filter(
        (setting) => setting.key === "fulfillment_details"
      )[0];

      state.paymentTypes = payload.payment_method_types;
      state.paymentMethods = payload.payment_methods;
      state.hasManyPaymentTypes = payload.payment_method_types.length > 1;
      state.bankAccount = payload.bank_account;

      state.isFetchingPaymentRequest = false;
      state.hasFetchingPaymentRequestErrored = false;
    },
    [actions.fetchPaymentRequestError.type]: (state) => {
      state.isFetchingPaymentRequest = false;
      state.hasFetchingPaymentRequestErrored = true;
    },

    [actions.catalogueLoadingStart.type]: (state, { payload }) => {
      state.isCatalogueLoading[payload] = true;
      state.hasCatalogueLoadingErrored[payload] = false;
    },
    [actions.catalogueLoading.type]: (state, { payload }) => {
      state.isCatalogueLoading[payload] = false;
      state.hasCatalogueLoadingErrored[payload] = false;
    },
    [actions.catalogueLoadingError.type]: (state, { payload }) => {
      state.isCatalogueLoading[payload] = false;
      state.hasCatalogueLoadingErrored[payload] = true;
    },
    [actions.fetchSocialStats.type]: (state, { payload }) => {
      state.transactionCountPastThreeMonths =
        payload.transaction_count_past_3_months;
    },

    [actions.fetchReviewsStart.type]: (state) => {
      state.isFetchingReviews = true;
      state.hasFetchingReviewsErrored = false;
    },
    [actions.fetchReviews.type]: (state, { payload }) => {
      state.isFetchingReviews = false;
      state.hasFetchingReviewsErrored = false;
      state.reviews = payload;
    },
    [actions.fetchReviewsError.type]: (state) => {
      state.isFetchingReviews = false;
      state.hasFetchingReviewsErrored = true;
    },
    [actions.setPaymentResult.type]: (
      state,
      action: { payload: PaymentResultData; type: string }
    ) => {
      state.paymentResult = { ...action.payload };
      state.paymentError = action.payload?.error;
    },
    [actions.selectCategoryUuid.type]: (state, action: { payload }) => {
      state.selectedCategoryUuid = action.payload;
    },
  },
});

export default catalogue;
