const {
  HAS_LOCAL_API,
  LOCAL_API_URL,
  STAGING_API_URL,
  PRODUCTION_API_URL,
  TEST_VGS_PROXY,
} = require("./config");

const RELEASE_CHANNEL = process.env.EXPO_RELEASE_CHANNEL;

function urlBasedOnReleaseChannel() {
  let url;
  if (RELEASE_CHANNEL === "production") {
    url = PRODUCTION_API_URL;
  } else if (HAS_LOCAL_API) {
    url = LOCAL_API_URL;
  } else {
    url = STAGING_API_URL;
  }
  return url;
}
// https://dashboard.verygoodsecurity.com/dashboard/v/VLT3c8FWWRX4Vd9X5aVE3kFck/routes/536ec272-5899-4b3c-b3b8-42c8bce3d510/edit#
// VGS is used as a proxy for tokenization
function getPaymentProxyUrl() {
  let url;
  if (RELEASE_CHANNEL === "production") {
    // vgs vault name: production
    url = "https://tokenization.cuanto.app";
  } else if (HAS_LOCAL_API) {
    // VGS only runs on local machines when explicitly turned on
    // vgs vault name: test
    url = TEST_VGS_PROXY
      ? "https://tokenization-test.cuanto.app"
      : LOCAL_API_URL;
  } else {
    url = "https://tokenization-staging.cuanto.app";
  }
  return url;
}

function addErrorInterceptor(axiosClient) {
  axiosClient.interceptors.response.use(
    function (config) {
      return config;
    },
    function (error) {
      const message = `${error.config.method.toUpperCase()} ${error.config.url
        } failed with status ${error.response.status}`;
      console.info(message);
      return Promise.reject(error);
    }
  );
}

function addRequestInterceptor(axiosClient) {
  axiosClient.interceptors.request.use(
    function (config) {
      const message = `${config.method.toUpperCase()} ${config.url}`;
      console.info(message);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export {
  urlBasedOnReleaseChannel,
  getPaymentProxyUrl,
  addErrorInterceptor,
  addRequestInterceptor,
};
