// Used from Node (into CI), do not use `import/export` syntax

// This file is ignored by git to avoid commiting changes we do during development to it.
// If you want to make a change, add them to the repository using `git add -f`

// Set this to true if you have an
// instance of the backend running locally
const HAS_LOCAL_API = false;

// Set this to true if you wish to tokenize credit card params through VGS
// the inbound and outbound VGS paths must be configured here:
// https://dashboard.verygoodsecurity.com/dashboard/v/VLT3c8FWWRX4Vd9X5aVE3kFck/routes
const TEST_VGS_PROXY = false;

// Set this to your local IP when Expo>Settings>LAN
// Example: '192.168.0.227';
// Works for simulator only
const HOST = "localhost:3000";
// Need LAN or Tunnel to use app on phone
// const HOST = "a86238d9.ngrok.io";

const LOCAL_API_URL = `http://${HOST}/`;
const STAGING_API_URL = "https://cuanto-staging.herokuapp.com/";
const PRODUCTION_API_URL = "https://cuanto.app/";

// An image cache & resize service
// https://github.com/weserv/images/
const IMAGES_API_URL = "https://images.cuanto.app";

module.exports = {
  HAS_LOCAL_API,

  TEST_VGS_PROXY,

  LOCAL_API_URL,
  STAGING_API_URL,
  PRODUCTION_API_URL,

  IMAGES_API_URL,
};
