import { createSlice } from "@reduxjs/toolkit";

import * as actions from "./actions";

import { Discount } from "../../../types/Discount";

const ENDPOINT = "discounts";

export interface State {
  data: Discount[];
  dataById: { [key: number]: Discount };

  selectedId: string;
  selectedUuid: string;

  isFetching: boolean;
  hasFetchingErrored: boolean;

  isCreating: boolean;
  hasCreatingErrored: boolean;

  isUpdating: boolean;
  hasUpdatingErrored: boolean;

  isDeleting: boolean;
  hasDeletingErrored: boolean;

  isCheckingDiscount: boolean;
  hasCheckingDiscountErrored: boolean;

  // Catalogue
  selectedCartDiscount: Discount;
}

const initialState: State = {
  data: [],
  dataById: {},

  selectedId: null,
  selectedUuid: null,

  isFetching: true,
  hasFetchingErrored: false,

  isCreating: false,
  hasCreatingErrored: false,

  isUpdating: false,
  hasUpdatingErrored: false,

  isDeleting: false,
  hasDeletingErrored: false,

  isCheckingDiscount: false,
  hasCheckingDiscountErrored: false,

  // Catalogue
  selectedCartDiscount: null,
};

const discounts = createSlice({
  name: ENDPOINT,
  initialState,
  reducers: {
    // Slice reducers can't use imported actions because they create new actions
  },
  extraReducers: {
    // Catalogue
    [actions.selectCartDiscount.type]: (state, { payload }) => {
      state.selectedCartDiscount = payload;
    },
    // Catalogue
    [actions.discountCheckStart.type]: (state) => {
      state.isCheckingDiscount = true;
      state.hasCheckingDiscountErrored = false;
    },
    [actions.discountCheckComplete.type]: (state) => {
      state.isCheckingDiscount = false;
      state.hasCheckingDiscountErrored = false;
    },
    [actions.discountCheckError.type]: (state) => {
      state.isCheckingDiscount = false;
      state.hasCheckingDiscountErrored = true;
    },
  },
});

export default discounts;
