import React from "react";

import CatalogueSocialProof from "./CatalogueSocialProof";

interface Props {
  transactionCount: number;
  cartQuantity: number;
}

const MIN_TRANSACTIONS_TO_SHOW_TOAST = 25;

const CatalogueSocialProofWrapper = ({
  transactionCount,
  cartQuantity,
}: Props) => {
  // only show the toast if the cart is empty and min transaction count is met
  if (transactionCount < MIN_TRANSACTIONS_TO_SHOW_TOAST || cartQuantity > 0) {
    return null;
  } else {
    return <CatalogueSocialProof count={transactionCount} />;
  }
};

export default CatalogueSocialProofWrapper;
